import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { IconButton, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import { pxToRem } from "@getbread/dough";
import { loanRoutes } from "../../pages/LoansPage/loanRoutes";
import { getConfig } from "../../utils/getConfig";
import { STYLE_CONSTANTS } from "../../utils/global.constants";
import { useConfirmLanguageChange } from "../ConfirmLanguageChange";
import { useLocale } from "../LocaleProvider";
import { HamburgerLanguages } from "./HamburgerLanguages";
import { HeaderExteriorLink, HeaderLink } from "./HeaderLink";
import { Icon } from "./Icon";
import { SlideIn } from "./SlideIn";

const NavButton = styled(IconButton)`
	&:focus-visible {
		outline-offset: 0;
		border-radius: ${pxToRem(2)};
	}

	&:hover {
		background-color: initial;
	}
`;

const NavDraw = styled(Modal)`
	background-color: ${({ theme }) => theme.palette.background.secondary};
	height: calc(100vh - ${STYLE_CONSTANTS.nav.headerHeight});

	/* RB: Since dvh support is not super broad, we have a regular vh fallback above this line */
	height: calc(100dvh - ${STYLE_CONSTANTS.nav.headerHeight});

	/* RB: allow scrolling vertically within the hamburger nav content if needed */
	overflow-y: auto;
`;

const LinkContainer = styled("ul")`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 var(--space-l);
	list-style-type: none;

	> li {
		margin-right: 0;
		text-align: center;
	}
`;

const MobileLink = styled(HeaderLink)`
	padding: var(--space-l);
	display: block;
`;
const MobileExteriorLink = styled(HeaderExteriorLink)`
	padding: var(--space-l);
	display: block;
`;

const LanguageSelectorListItem = styled("li")`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: ${pxToRem(8)};
`;

const HamburgerNav: React.FC = () => {
	const intl = useIntl();
	const [menuOpen, setMenuOpen] = useState(false);
	const { locale } = useLocale();

	const [openLanguageChange, LanguageChangeModal] = useConfirmLanguageChange();

	const faqLinks = getConfig("faqlinks").general;
	const faqLink = faqLinks[locale];

	const hasMultipleLanguages = getConfig("locales").length > 1;

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};
	const closeMenu = () => {
		setMenuOpen(false);
	};

	return (
		<nav>
			{LanguageChangeModal}
			<NavButton
				aria-label={
					menuOpen
						? intl.formatMessage({
								defaultMessage: "Close Navigation Menu",
						  })
						: intl.formatMessage({
								defaultMessage: "Open Navigation Menu",
						  })
				}
				onClick={toggleMenu}
				data-testid="hamburger-nav"
			>
				<Icon open={menuOpen} />
			</NavButton>
			<SlideIn transitionOn={menuOpen}>
				<NavDraw
					open={menuOpen}
					disablePortal
					hideBackdrop
					disableAutoFocus
					disableEnforceFocus
					onClose={(_event, reason) => {
						if (reason === "escapeKeyDown") {
							closeMenu();
						}
					}}
					style={{ top: STYLE_CONSTANTS.nav.headerHeight }} // needs to be a style prop to override the default Modal style
				>
					<LinkContainer onClick={closeMenu}>
						<li>
							<MobileLink to={`/${loanRoutes.base}`} data-testid="mobile-loans-link">
								<FormattedMessage defaultMessage="Loans" />
							</MobileLink>
						</li>
						<li>
							<MobileLink to="/account" data-testid="mobile-account-link">
								<FormattedMessage defaultMessage="Account" />
							</MobileLink>
						</li>
						<li>
							<MobileExteriorLink
								href={faqLink}
								target="_"
								data-testid="mobile-faqs-link"
							>
								<FormattedMessage defaultMessage="FAQs" />
							</MobileExteriorLink>
						</li>
						<li>
							<MobileLink to="/logout" data-testid="mobile-sign-out-link">
								<FormattedMessage defaultMessage="Sign Out" />
							</MobileLink>
						</li>

						{hasMultipleLanguages && (
							<LanguageSelectorListItem>
								<HamburgerLanguages changeLanguage={openLanguageChange} />
							</LanguageSelectorListItem>
						)}
					</LinkContainer>
				</NavDraw>
			</SlideIn>
		</nav>
	);
};

export { HamburgerNav };
