import { type Locale, pxToRem } from "@getbread/dough";
import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useIntl } from "react-intl";
import { abbreviatedLanguageNames, languageNames } from "../../utils/i18n";

const StyledSelect = styled(Select)`
	&.MuiInputBase-root {
		border: none;

		:focus-within {
			outline: ${({ theme }) => `${pxToRem(2)} solid ${theme.palette.colors.focus}`};
		}
	}

	& .MuiInputBase-input {
		color: ${({ theme }) => theme.palette.text.primary};
		font-weight: 500;
		font-size: ${pxToRem(16)};
		padding: ${pxToRem(2, 8)};
	}

	& .MuiSelect-icon {
		color: black;
	}

	& .MuiSelect-selectMenu {
		min-height: 0;
	}
`;

type LocaleDropdownProps = {
	selected: string;
	selectLocale: (locale: Locale) => void;
};

const LocaleDropdown: React.FC<LocaleDropdownProps> = ({ selected, selectLocale }) => {
	const intl = useIntl();
	const onChange: React.ComponentProps<typeof Select>["onChange"] = (ev) => {
		const locale = ev.target.value as Locale;
		selectLocale(locale);
	};

	const languages = languageNames();
	const languageAbbvs = abbreviatedLanguageNames();

	return (
		<StyledSelect
			data-testid="locale-dropdown"
			onChange={onChange}
			value={selected}
			renderValue={() => languageAbbvs[selected]}
			aria-label={intl.formatMessage({ defaultMessage: "Select Website Language" })}
			tabIndex={-1}
		>
			{Object.keys(languages).map((locale) => (
				<MenuItem key={locale} value={locale}>
					{languages[locale]}
				</MenuItem>
			))}
		</StyledSelect>
	);
};

export { LocaleDropdown };
